ul{
    list-style:none;
    margin: 0;
    padding:0;
}

small{
    font-weight:inherit;
    font-weight: auto;
}

h1,h2,h3,h4,h5{
    font-weight:bold;
}

label {
    margin: 0;
}