footer{
    margin-top: 1.5rem;
    #footer-map{
        position: relative;
        padding-top: 50%;
        @include mq-min(lg){
            padding-top: 40%;
        }
        @include mq-min(xl){
            padding-top:30rem;
        }
        iframe{
            position: absolute;
            top:0;
            left:0;
            width: 100%;
            height:100%;
        }
    }
}
#copy-rights{
    display: block;
    text-align: center;
    background:$color-m01;
    font-size: 0.5rem;
    color:#fff;
    padding:0.5rem;
}

