/*#sidebar {
    display: none;
    @include mq-min(lg) {

        padding-top: 2.5rem ;
        padding-bottom:2.5rem;
    }
}*/

.side-banner {
    li {
        list-style: none;
        margin-bottom: 1em;
    }
}

#sidebar {
    @include mq-min(lg){
        font-size:0.9rem;
    }
    .section-basis {
        padding: 1rem 0;
    }
    .heading05-01 {
        margin-top: 0;
    }
    .clinic-schedule {
        margin-bottom: 0;
    }
    .table {
        @include mq-min(lg) {
            th,
            td {
                font-size: 0.85em;
                padding: .6em .25em;
            }
        }
    }
    .link-area {
        background: $color-m01;
        padding: 15px;
        border-radius: 3px;
        color: $color-WH;
        max-width: 300px;
        margin: 0 auto;
        .btn-border {
            margin: 0 0 0.5em;
            a {
                display: block;
                width: 100%;
                background: $color-WH;
                border: none;
                box-shadow: 0 3px 0 0 rgba($color-BK, 0.2);
                color: darken($color-txt, 10);
                i {
                    color: $color-m01;
                    padding: relative;
                    /*&:after{
                        content:"";
                        position: absolute;
                        top:-0.25em;
                        left:-0.25em;
                        width:1.5em;
                        height:1.5em;
                        background:$color-m01;
                        border-radius:50%;
                    }*/
                }
            }
            /*
            &.interview-sheet{
                a{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    i{
                        width: 1em;
                        margin-right:1em;
                    }
                    p{
                        text-align: left;
                        margin-bottom: 0;
                        strong{
                            font-size: 1.25rem;
                        }
                        small{
                            display: block;
                            color: rgba($color-BK,0.4);
                        }
                    }
                }
            }
            */
        }
        .qr-code {
            dt {

                margin: 1.0em 0 .5em;
            }
        }
    }
    
    #wp-calendar{
        width:100%;
        text-align:center;
        th,td{
            border-right:1px solid $color-line;
            border-top:1px solid $color-line;
            &:last-of-type{
                border-right:none;
            }
        }
        thead{
            background-color: rgba($color-m01,0.1);
            th{
                border-top:none;
                border-color:rgba($color-m01,0.2);
            }
        }
        caption,tfoot{
            display:none;
        }
    }
}
