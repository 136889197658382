$header-bg:#fff;
$header-c:#000;
$nav-bg:$color-m01;
$nav-c:#fff;


.drawer--navbarTopGutter {
    padding-top: 55px;
    @include mq-min(lg) {
        padding-top: 188px;
    }
}

.drawer-navbar {
    background: $color-b01;
    border-bottom: 3px solid darken($color-m01, 5%);
    @include mq-min(lg) {
        border: none;
        height: auto;
        background: $color-b02;
    }
    #header-upper {
        .drawer-navbar-header {
            background-color: transparent;
            height: auto;
            height: initial;
            text-align: left;
            border-bottom: none;
            @include mq-min(lg) {
                float: none;
            }
            .drawer-brand {
                width: 222px;
                text-align: left;
                line-height: 1.0;
                padding: 10px 0;
                @include mq-min(lg) {
                    float: left;
                    width: 356px;
                    padding-top: 30px;
                    padding-bottom: 30px;
                }
            }
            ul {
                list-style: none;
                @include mq-min(lg) {
                    display: none;
                }
                %list-style {
                    width: 55px;
                    height: 55px;
                    font-size: 0.6em;
                    text-align: center;
                    line-height: 1.0;
                    color: darken($color-txt, 10);
                    text-decoration: none;
                    padding: 10px;
                    border-left: 1px solid rgba($header-c, 0.15);
                    /*.drawer-open & {
                        color: $nav-c;
                        border-left: 1px solid rgba($nav-c, 0.3);
                        border-bottom: 1px solid rgba($nav-c, 0.3);
                    }*/
                }
                .drawer-toggle.drawer-hamburger {
                    @extend %list-style;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    span.drawer-hamburger-icon {
                        background: darken($color-txt, 10);
                        margin: 10px 0 14px;
                        -webkit-border-radius: 1px;
                        -moz-border-radius: 1px;
                        -ms-border-radius: 1px;
                        -o-border-radius: 1px;
                        border-radius: 1px;
                        &:before {
                            background: $header-c;
                            -webkit-border-radius: 1px;
                            -moz-border-radius: 1px;
                            -ms-border-radius: 1px;
                            -o-border-radius: 1px;
                            border-radius: 1px;
                            top: -8px;
                            .drawer-open & {
                                top: 0; //background: $color-WH;
                            }
                        }
                        &:after {
                            background: darken($color-txt, 10);
                            -webkit-border-radius: 1px;
                            -moz-border-radius: 1px;
                            -ms-border-radius: 1px;
                            -o-border-radius: 1px;
                            border-radius: 1px;
                            top: 8px;
                            .drawer-open & {
                                top: 0; //background: $nav-c;
                            }
                        }
                        body.drawer-open & {
                            background: transparent;
                        }
                    }
                }
                #header-tel {
                    position: fixed;
                    top: 0;
                    right: 55px;
                    a {
                        @extend %list-style;
                        display: block;
                        i {
                            display: block;
                            font-size: 1.5rem;
                            margin-bottom: 2px;
                        }
                    }
                }
            }
            #header-info {
                @include mq-min(lg) {
                    float: right;
                    display: flex !important;
                    margin-top: 15px;
                    > li {
                        margin-left: 4px;
                    }
                    .contact-area {
                        ul {
                            display: block;
                            font-size: 1.0rem;
                            %li {
                                padding: 0.25em 1.0em;
                                border-radius: 3px;
                                font-weight: bold;
                            }
                            li {
                                i {
                                    margin-right: 0.5em;
                                }
                                &.tel-number {
                                    border: 3px solid $color-m01;
                                    color: $color-m01;
                                    @extend %li;
                                    padding-top: calc(0.25em - 2px);
                                    padding-bottom: calc(0.25em - 2px);
                                }
                                &#header-contact {
                                    margin-top: 0.25em;
                                    a {
                                        @extend %li;
                                        display: block;
                                        color: #fff;
                                        text-decoration: none;
                                    }
                                }
                            }
                        }
                    }
                    .qr-code {
                        margin-top: 0;
                        a {
                            width: 82px;
                            padding: 5px;
                            border-radius: 3px;
                            border: none;
                        }
                    }
                    /*padding:10px 10px 13px;
                            border-radius:8px;
                           border:1px solid #fff; background:rgba(0,0,0,0.01);
                            box-shadow:1px 1px 1px 0 rgba(0,0,0,0.1) inset;
                            display: block;
                            display: flex;
                            .btn{
                                margin-top: 0;
                                padding:0;
                                a{
                                border-radius: 5px 0 0 5px;
                                    border-right:1px solid rgba($color-BK,0.1);
                                }
                    }*/
                }
            }
        }
        .clinic-schedule {
            float: right;
            width: initial;
            width: auto;
            margin: 15px 0;
            background: $color-WH;
            border: 3px solid $color-a01;
            border-radius: 5px;
            font-size: 0.8rem;
            tr {
                th,
                td {
                    padding: 0.3em 0.5em;
                    font-weight: normal;
                    border-right-color: rgba($color-BK, 0.1);
                    border-bottom-color: rgba($color-BK, 0.1);
                    &:last-child {
                        border-right: none;
                    }
                }
                th {
                    letter-spacing: 0.05em;
                }
            }
            thead {
                tr {
                    th {
                        background: $color-a01;
                        color: $color-WH;
                        border-top: 0;
                        border-right: 1px solid rgba($color-WH, 0.2);
                    }
                }
            }
            tbody {
                tr {
                    &:first-of-type {
                        th,
                        td {
                            border-top: none;
                        }
                    }
                    &:last-of-type {
                        th,
                        td {
                            border-bottom: none;
                        }
                    }
                    th {
                        padding-left: 0.75em;
                        padding-right: 0.75em;
                    }
                    td {
                        color: $color-a01;
                    }
                }
            }
        }
    }


    #header-nav {
        background: $color-WH;
        @include mq-min(lg) {
            border-top: 1px solid $color-line;
            &:after {
                content: "";
                position: absolute;
                bottom: -3px;
                left: 0;
                width: 100%;
                height: 4px;
                background: darken($color-WH, 15%);
            }
        }
        .drawer-nav {
            background: $color-m01;
            color: $color-WH;
            padding-top: 0;
            margin-top: 55px;
            @include mq-min(lg) {
                margin-top: 10px;
                text-align: center;
                background: transparent;
                color: $color-txt;
            }
            li {
                border-bottom: 1px solid rgba($color-BK, 0.1);
                box-shadow: 0 1px 0 0 rgba($color-WH, 0.4);
                @include mq-min(lg) {
                    width: 20%; //position:relative;
                    border: none;
                    box-shadow: none;
                    /*&:before{
                        content:"";
                        position:absolute;
                        height:2.0em;
                        top:calc(50% - 1em);
                        left:0;
                        border-left:1px solid darken($color-m01,5%);
                    }
                    &:first-of-type{
                        &:before{
                            display:none;
                        }
                    }*/
                }
                a {
                    color: #fff;
                    text-decoration: none;
                    font-size: 1rem;
                    display: block;
                    padding: 0.75rem;
                    @include mq-min(lg) {
                        position: relative;
                        color: darken($color-txt, 10%);
                        font-size: 1.0rem;
                        font-weight: bold;
                            line-height: 3.75rem;
    padding-top: 0;
    padding-bottom: 0;
                        &:after {
                            content: "";
                            position: absolute;
                            right: 0;
                            top: 0.75rem;
                            width: 1px;
                            height: calc(100% - 1.5rem);
                            background-color: $color-line;
                        }
                        &:hover {
                            background-color: $color-m01;
                            color: #fff;
                            box-shadow: 0 4px 0 0 darken($color-m01, 20);
                            border-radius: 3px 3px 0 0;
                            i {
                                color: #fff;
                            }
                            &:after {
                                content: none;
                            }
                        }

                    }
                    i {
                        @include mq-min(lg) {
                            color: $color-m01;
                            font-size: 1.75rem;
                            vertical-align: middle;
                        }
                    }
                    span {
                        @include mq-min(lg) {
                            vertical-align: middle;
                        }
                    }
                }
                @include mq-min(lg) {
                    &:first-of-type {
                        a {
                            &:before {
                                content: "";
                                position: absolute;
                                left: 0;
                                top: 0.75rem;
                                width: 1px;
                                height: calc(100% - 1.5rem);
                                background-color: $color-line;
                            }
                            &:hover {
                                content: none;
                                &:before {
                                    content: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
