@charset "utf-8";

// 配色 ******** 

//原色
$color-BK:#000;
$color-WH:#fff;
$color-RD:#E9194B;

//サイトカラー
$color-b01:#FFFFFF;
$color-b02:#f5f5f2;
$color-m01:#54B765;
$color-m02:#227C4A;
$color-a01:#fd979e;
$color-a02:#FFC700;

//部位カラー
$color-txt:#545454;
$color-line:#eaeaea;
$color-caution:#E9194B;
$color-mark:#fff900;
$color-weak:#A1A1A1;

//その他 カラー
$color-o01:#0e3c59; //
$color-o02:#0e3c59; //
$color-o03:#0e3c59; //
$color-o04:#0e3c59; //
$color-o05:#0e3c59; //



// パーツ ******** 
$lh00:1.7;