/***
* .container .row ***********/

#container {
    //PC2カラムを採用する際の横幅制御（スマホ閲覧時含む）
    @include mq-max(lg) {
        max-width: 100%;
        padding: 0;
        > .row {
            margin: 0;
            #main,
            #sidebar {
                padding: 0;
                > .row {
                    margin: 0;
                    > .col-12 {
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
            }
        }
    }
    #main,
    #sidebar {
        @include mq-min(lg) {
            .section-basis.bg-b02 {
                padding-right: 15px;
                padding-left: 15px;
            }
            .container {
                width: 100%;
                max-width: 100%;
                padding: 0;
            }
        }
    }
}

.container-s {
    @include mq-min(lg) {
        max-width: 768px;
    }
}

.container-m {
    @include mq-min(lg) {
        max-width: 960px;
    }
}

.container-l {
    @include mq-min(xl) {
        max-width: 1280px;
    }
}

.section-basis {
    padding-top: 2.0rem;
    padding-bottom: 2.0rem;
    @include mq-min(lg) {
        padding-top: 5.0rem;
        padding-bottom: 5.0rem;
        #container & {
            padding-top: 2.0rem;
            padding-bottom: 2.0rem;
        }
    }
}

.row-5 {
    @include row(5px);
}

.row-10 {
    @include row(10px);
}

.row-20 {
    @include row(20px);
}

.row-lg5 {
    @include mq-min(lg) {
        @include row(5px);
    }
}

.row-lg10 {
    @include mq-min(lg) {
        @include row(10px);
    }
}

.row-lg20 {
    @include mq-min(lg) {
        @include row(20px);
    }
}

/*見出し*/

h3.heading03-01 {
    border-top: 1px solid $color-line;
    border-bottom: 1px solid $color-line;
    color: $color-m01;
    padding: 0.5em 0;
    @include mq-min(lg) {
        margin-bottom: 1.0rem;
    }
}

h3.heading03-02 {
    background: rgba($color-WH, 0.5);
    padding: 0.75em;
    border-top: 4px solid $color-m01;
    border-bottom: none;
    color: $color-txt;
}

.heading04-01 {
    font-size: 1.4em;
    padding-bottom: 0.5em;
    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: $color-m01;
        border-radius: 5px;
    }
}

.heading04-02 {
    background: rgba($color-WH, 0.5);
    padding: 0.5em;
    border-top: 3px solid $color-m01;
    border-bottom: none;
    color: $color-txt;
}



.heading05-01 {
    padding: 0.2em 0 0.2em .75em;
    color: $color-m01;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0.25em; //height:1.2em;
        height: 100%;
        border-radius: .125em;
        background-color: $color-m01;
    }
}

.heading05-02 {
    letter-spacing: 0.1em;
    color: darken($color-m01, 10%);
    .mark {
        background: linear-gradient(transparent 50%, rgba($color-m01, 0.25) 50%, rgba($color-m01, 0.25) 90%);
    }
}

.heading06-01 {
    &:before {
        content: "ー";
        color: $color-m01;
    }
}

.heading06-02 {
    &:before {
        content: "・";
        color: $color-m01;
    }
}


/***
* TEXT ***********/

.small {
    font-size: 0.9em;
    margin-bottom: 0;
}

.txt {
    font-size: 0.9em;
    color: $color-txt;
    line-height: 1.4;
}

.txt-caution {
    color: $color-RD;
    &:before {
        content: "※";
    }
}
.line-wave {
    position: relative;
    padding: 0 0.25em;
    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 5px;
        background: url(../images/share/basis/line-wave-OR02.svg) repeat-x;
    }
}

.c-m01 {
    color: $color-m01;
}

.c-m02 {
    color: $color-m02;
}

.fas,
.far {
    margin-right: 0.25em;
    &.after{
        margin:0 0 0 1.0em;
    }
}

.img-default{
    background: url(../images/share/basis/default-image.svg) no-repeat center;
    padding-top:33.333333333333%;
    padding-top: calc(2 / 3 * 100%);
    background-size: cover;
    border-radius:5px ;
}

.img-frame{
    img{
        border:3px solid #fff;
    }
}

.img-circle {
    img {
        border-radius: 50%;
    }
}

.img-radius{
    img{
    border-radius:5px;
    }
}
.disc{
 list-style:disc;   
    margin-left:3px;
}


/***
* backgorund ***********/

.bg-wh {
    background: #fff;
}

.bg-b01 {
    background: $color-b01;
}

.bg-b02 {
    background: $color-b02;
}

.bg-m01 {
    background: $color-m01;
}

.bg-m02 {
    background: $color-m02;
}

.bg-img01 {
    background: url(../images/share/basis/bg-img01.png) repeat;
}






/***
* リスト **********/

.list {
    line-height: 1.3;
    li {
        padding: 0.2em 0;
    }
    &.line {
        li {
            border-bottom: 1px solid $color-weak;
        }
    }
}



/***
* シャドウ *********/

.shadow01 {
    position: relative;
    &:before,
    &:after {
        content: "";
        z-index: -1;
        position: absolute;
        bottom: 3%;
        left: 5%;
        width: 50%;
        height: 10%;
        box-shadow: 0 15px 10px rgba(0, 0, 0, 0.15);
        transform: rotate(-5deg);
    }
    &:after {
        transform: rotate(5deg);
        left: 95%;
        right: 5%;
        left: auto;
    }
}


/***
* ボタン **********/

%btn-share {
    -webkit-transition: all .3s;
    transition: all .3s;

}

.btn {
    padding: 0;
    margin: 0.75em 0 0;
    display: block;
    border:none;
    a,.btn-inner {
        display: inline-block; //border: 3px solid $color-a01;
        position: relative;
        padding: 0.5em 1.0em;
        text-decoration: none;
        @extend %btn-share;
        &:hover {
            @extend %btn-share;
        }
        &:before,
        &:after {
            position: absolute;
            z-index: -1;
            display: block;
            content: '';
            @extend %btn-share;
        }
    }
    &.btn01 {
        a,.btn-inner {
            background: $color-m01;
            border-radius: 3px;
            box-shadow: 0 3px 0 0 darken($color-m01, 10);
            font-weight:bold;
            letter-spacing:0.1em;
            &:hover {
                transform: translateY(3px);
                box-shadow: none !important;
            }
        }
        &.WH{
            a{
            background: $color-WH;
                color:$color-m01;
            }
        }
        &.btn-border{
            a,.btn-inner{
                border:3px solid $color-m01;
                background: $color-WH;
                color: $color-m01;
                box-shadow:0 3px 0 0 rgba(0,0,0,0.1);
            }
        }
    }
}




.btn03 a {
    position: relative;
    z-index: 2;
    color: #000; //line-height: 50px;
    font-weight: bold;
    -webkit-perspective: 300px;
    perspective: 300px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    &:hover {
        color: #fff;
    }
    &:after {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $color-a01;
        -webkit-transform-origin: center bottom;
        transform-origin: center bottom;
        -webkit-transform: rotateX(0);
        transform: rotateX(0);
    }
    &:hover::after {
        -webkit-transform: rotateX(-180deg);
        transform: rotateX(-180deg);
    }
}

.detail{
    font-weight: bold;
    color: #54b765;
    letter-spacing: 0.1em;
    margin-top: 0.5em;
    text-align: right;
}

/***
* テーブル **********/

.table {
    width: 100%;
    tr {
        th,
        td {
            padding: 0.5em 1em;
            line-height: 1.3;

        }
    }
    &.responsive {
        th,
        td {
            display: block;
            @include mq-min(md) {
                display: table-cell;
            }
        }
        th{
            background: darken($color-b01,5);
            @include mq-min(md){
                background-color:initial;
                backgroudn-color:auto;
            }
        }
    }
}

.table01 {
    tr {
        //border-top: 1px solid $color-m01;
        //border-left: 1px solid $color-m01;
        background: #fff;
        th,
        td {
            //border-right: 1px solid $color-m01;
            //border-bottom: 1px solid $color-m01;
            position: relative;
            display: block;
            border: none; //padding: 0.5em 1em; //line-height: 1.3;
            @include mq-min(lg) {
                display: table-cell;
            }
        }
        th {
            background: $color-b01;
            color: #fff;
            @include mq-min(lg) {
                width: 25%;
                background: $color-m01;
            }
        }
        td {
            padding-top: 0.75em;
            padding-bottom: 0.75em;
            @include mq-min(lg) {
                background: rgba($color-b01, 0.15);
                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    background: rgba($color-m01, 0.2);
                    height: 100%;
                    width: 5px;
                    top: 0;
                    left: 0;
                }
            }
        }
        &:nth-of-type(2n-1) {
            @include mq-min(lg) {
                th {
                    background: #34a26b;
                }
                td {
                    background: lighten($color-b02, 3%);
                }
            }
        }
    }
}

.table02 {
    border-left: 1px solid $color-line;
    tr {
        %cell {
            border-right: 1px solid $color-line;
            border-bottom: 1px solid $color-line; //padding: 0.5em 1em;
        }
        th {
            background: $color-b02;
            @extend %cell;
        }
        td {
            @extend %cell;
        }
        &:first-of-type {
            th {
                border-top: 3px solid $color-line;
            }
            td {
                border-top: 3px solid $color-m01;
            }
        }
    }
    @include mq-max(md) {
        &.responsive {
            border: none;
            tr {
                th,
                td {
                    border: none;
                    border-bottom: 1px solid $color-line;
                }
                &:first-of-type {
                    th {
                        border-top: 3px solid $color-m01;
                    }
                }
            }
        }
    }
}

.table-border {
    tr {
        %cell {
            border-bottom: 1px solid $color-line;
        }
        th {
            @extend %cell;
        }
        td {
            @extend %cell;
        }
        &:first-of-type {
            %cell {
                border-top: 1px solid $color-line;
            }
        }
    }
}


/***
* Article **********/

.article-card {
    article {
        > .inner {
            padding: 15px;
            margin-bottom: 1.5rem;
            background: $color-b01;
            border-radius: 3px;
            .bg-b02 & {
                background: $color-WH;
            }
            .img-area {
                margin-bottom: 0;
                &.col-md-3,
                &.col-md-4 {
                    @include mq-min(md) {
                        margin-bottom: 0;
                    }
                }
            }
            .txt-area {
                padding-top: 15px;
                h5 {
                    margin-top: 0;
                }
                time {
                    display: block;
                    margin-bottom: 0.5em;
                }
                p.txt {
                    margin-bottom: 0;
                }
            }
        }
        > a {
            transition: all .3s;
            display: block;
            h5{
                color:$color-m01;
            }
            &.inner:hover {
                background: $color-m01;
                color: $color-WH;
                transition: all .3s;
                h5,
                time,
                .txt {
                    color: $color-WH;
                    transition: all .3s;
                }
            }
        }
    }
    &.pd-none {
        article {
            > .inner {
                padding: 0;
                .txt-area {
                    padding: 15px;
                }
            }
        }
    }
}

.article-catalog {
    article {
        .inner {
            padding: 15px 10px;
            @include mq-min(lg) {
                padding: 15px;
            }
            @include mq-min(xl) {
                padding: 30px;
            }
            p {
                margin-bottom: 0;
            }
            h4 {
                margin-bottom: 0.25em;
                time {
                    display: block;
                    margin-bottom: 0.2em;
                }
            }
        }
        &:nth-of-type(2n) {
            .inner {
                background: darken($color-b02, 2);
            }
        }
        &:last-of-type {
            .inner {
                border-bottom: none;
            }
        }
        a.inner{
            display: block;
            transition: all .3s;
            text-decoration: none;
            //border:3px solid transparent;
            &:hover{
                background:rgba($color-m01,0.1);
                //border:3px solid $color-m01;
                color:$color-WH;
                transition: all .3s;
            }
            
        }
    }
}



/***
* FORM **********/

.checkbox {
    display: none;
    & + .checkbox-icon {
        position: relative;
        vertical-align: middle;
        &:before {
            content: "\f14a";
            font-family: "Font Awesome 5 Free";
            color: rgba($color-weak, 0.25);
            font-size: 2.0rem;
            @include mq-min(lg) {
                font-size: 2.5em;
                font-size: 40px;

            }
        }
    }
    &:checked + .checkbox-icon:before {
        content: "\f14a";
        color: $color-m01;
    }
}
