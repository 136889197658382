/*#main {
    @include mq-min(lg) {
    }
    .container {
        @include mq-min(lg) {
            padding: 0;
        }
    }
    .row {
        @include mq-min(lg) {
            margin: 0;
        }
    }
}*/



.clinic-schedule{
    tr{
        th,td{
            padding:.25em;
            text-align:center;
            vertical-align:middle;
        }
    }
}

.btn-area {
    display: block;
    margin-top: 2.0rem;
    text-align: center;
    cursor: pointer;
    a,
    button {
        display: block;
        width: 100%;
        background: $color-a01;
        border: none;
        border-radius: 0;
        font-size: 1.4rem;
        font-weight: bold;
        color: $color-WH;
        line-height: 1.2;
        letter-spacing: 0.2em;
        padding: 0.75em;
        text-decoration: none;
        @include mq-min(lg) {
            display: inline-block;
            width: initial;
            width: auto;
            padding-left: 3.5em;
            padding-right: 3.5em;
        }
    }
}

.txt-caution {
    font-size: 0.8rem;
    line-height: 1.3;
    text-align: center;
}

.page-menu {
    list-style: none;
    margin-bottom: 1.0em;
    #main & {
        @include mq-min(lg) {
            margin-bottom: 2.0rem;
        }
    }
    li {
        padding: 0;
        border-right: 1px solid #0e3c59;
        &:last-of-type {
            border: none;
        }
        a {
            display: block;
            background-color: $color-m01;
            color: $color-WH;
            text-align: center;
            padding: 0.2em;
            text-decoration: none;
            cursor: pointer;
            @include mq-min(lg) {
                padding: 0.25em;
                font-size: 1.25em;
                font-weight: bold;
                letter-spacing: 0.2em;

            }
        }
    }
}

.file-attachment {
    margin: 2.0rem 0;
    text-align: center;
    a {
        padding: 0.75em 1.5em;
        background: $color-a01;
        color: $color-WH;
        text-decoration: none;
    }
}

/* HOME */

.article {
    text-align: center;
    article {
        z-index: 0;
        margin-bottom: 15px;
        .txt-area,
        .txt {
            margin-bottom: 0;
        }
    }
}

.article-column {
    article {
        .inner {
            border-bottom: 1px dashed darken($color-b02, 10%);
            padding-bottom: 0.5rem;
            margin-bottom: 0.5rem;
            .img-area {
                padding-right: 0;
                margin-bottom: 0
            }
            .txt-area {
                h5.heading {
                    text-align: left;
                    margin-bottom: 0.2em;
                    font-size: 1.0em;
                }
                .txt {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.article-number {
    article {
        .inner {
            height: 100%;
            i.number {
                position: absolute;
                top: 0;
                left: 15px;
                width: 3.0rem;
                height: 3.0rem;
                background: $color-m01;
                color: $color-WH;
                text-align: center;
                line-height: 3.0rem;
                font-style: normal;
                font-weight: bold;
            }
        }
    }
}

#main-image {
    position: relative;
    background: url("../images/home/main-image03.jpg") no-repeat;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    padding: 3.0rem 0;
    margin-bottom: 1.5rem;;
    color: #fff;
    text-align: center;
    @include mq-min(lg) {
    }
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background: rgba(0, 0, 0, 0.1) url(../images/layer-dot.png) repeat;
    }
    h2.heading {
        position: relative;
        font-size: 1.2em;
        margin: 0;
        @include mq-min(sm) {
            font-size: 2.0rem;

        }
        @include mq-min(md) {
            font-size: 2.5rem;

        }
        @include mq-min(xl) {
            font-size: 3.0rem;

        }
    }
}

/*
#section-reason {
    padding-top: 1.5rem;
    h3.heading03-02 {
        margin-bottom: 2.25rem;
        @include mq-min(lg) {
            margin-bottom: 4.0rem;
        }
    }
    .article {
        .inner {
            background: #fff;
            .img-area {
                margin-bottom: 0;
                border-bottom: 3px solid $color-m01
            }
            .txt-area {
                padding: 1.0rem 0.5rem;
            }
        }
    }
}
*/


.fee-area {
    .fee {
        text-align: center;
        margin-bottom: 0.25em;
        @include mq-min(md) {
            margin: 1.0rem 0;
        }
        > * {
            display: inline;
        }
        dd {
            span {
                font-size: 2.0em;
                font-weight: bold;
                color: #ff5a5a;
                line-height: 1;
                @include mq-min(md) {
                    font-size: 2.5rem;
                }
            }
        }
    }
    .content {
        dt {
            background: $color-m01;
            color: $color-WH;
            display: inline-block;
            padding: 0.05em 1.5em;
            margin-bottom: 0.4em;
        }
        dd {
            .txt {
                margin: 0;
            }
        }
    }
}

.campaign-area {
    background: $color-m01;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    color: $color-b01;
    > * {
        color: $color-b01;
        margin-bottom: 0;
        text-align: center;
    }
    h5.heading {
        font-size: 1.4em;
    }
    .fee {
        text-align: center;
        color: $color-a01;
        font-weight: bold;
        span {
            font-size: 2.0em;
        }
    }
}

#section-problem {
    @include mq-min(lg) {
        position: relative;
        background: url(../images/problem-background.jpg) no-repeat;
        background-size: cover;
        background-attachment: fixed;
        &:before {
            content: "";
            background: inherit;
            filter: blur(5px);
        }
    }
    article {
        position: relative;
        @include mq-min(lg) {
            padding: 0;
        }
        /*@include mq-min(sm) {
            padding: 15px;
            border-bottom: 1px solid #decfbf;
            border-right: 1px solid #decfbf;
            &:before,
            &:after {
                display: block;
                content: "";
                position: absolute;
                top: calc(100% - 9px);
                right: -1px;
                width: 1px;
                height: 20px;
                background: #decfbf;
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }*/
        .inner {
            @include mq-min(lg) {
                background: rgba(255, 255, 255, 0.9);
                margin: 0 1px 1px 0;
                padding: 15px;
            }
            p.img-area {
                img {
                    border: 3px solid rgba(0, 0, 0, 0.10);
                }
            }

            h5.heading {
                color: #109954;
            }
            .txt {
                font-size: 0.8em;
            }
        }
    }
}

#section-fee {
    .row.catalog-fee {
        article {
            > .inner {
                padding: 40px 15px;
                border-radius: 0.25em;
                &.OR {
                    background: $color-BK;
                    h4.heading,
                    .txt {
                        color: $color-WH;
                    }
                    .btn01 {
                        a {
                            color: $color-BK;
                            &:hover {
                                color: $color-a01;
                            }
                        }
                    }
                }
                &.color-BK {
                    background: $color-BK;
                    h4.heading,
                    .txt {
                        color: $color-WH;
                    }
                    .btn01 {
                        a {
                            color: $color-BK;
                            &:hover {
                                color: $color-a01;
                            }
                        }
                    }
                }
                &.BL {
                    background: $color-BK;
                    h4.heading,
                    .txt {
                        color: $color-WH;
                    }
                    .btn01 {
                        a {
                            color: $color-BK;
                            &:hover {
                                color: $color-a01;
                            }
                        }
                    }
                }
                .img-area {
                    padding-right: 25px;
                    padding-left: 25px;
                    img {
                        border-radius: 50%;
                    }
                }
                h4.heading {
                    position: relative;
                    letter-spacing: 0.1em;
                    padding-bottom: 0.75em;
                    span {
                        display: block;
                    }
                    .eng {
                        font-size: 0.5em;
                        font-style: normal;
                        letter-spacing: 0.5em;
                    }
                    &:after {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        left: calc(50% - 10%);
                        background: #fff;
                        width: 20%;
                        height: 3px;
                    }
                }
            }
        }
    }
}


.fee-pack {
    margin-bottom: 1.5rem;
    @include mq-min(lg) {
        margin-top: 2.5rem;
    }
    .container > .inner {
        border: 3px solid $color-m01;
        background: $color-WH;
        padding: 1.5em 15px;
        h4.heading04-01 {
            @include mq-min(md) {
                font-size: 1.5rem;
                letter-spacing: 0.1em;
                margin-bottom: 1rem;
            }
            @include mq-min(lg) {
                font-size: 1.8rem;
                margin-bottom: 1.5rem;
            }
        }
        article {
            position: relative;
            padding-top: 20px;
            padding-bottom: 20px;
            border-bottom: 1px dashed darken($color-b02, 10%);
            text-align: center;
            @include mq-min(sm) {
                border-right: 1px dashed darken($color-b02, 10%);
                &:nth-of-type(2n) {
                    border-right: none;
                }
            }
            @include mq-min(md) {
                &:nth-of-type(2n) {
                    border-right: 1px dashed darken($color-b02, 10%);
                }
                &:nth-of-type(3n) {
                    border-right: none;
                }
            }
            @include mq-min(lg) {
                &:nth-of-type(3n) {
                    border-right: 1px dashed darken($color-b02, 10%);
                }
                &:nth-of-type(4n) {
                    border-right: none;
                }
            }
            /*&:before,
                &:after {
                    content: "";
                    position: absolute;
                    display: block;
                    top: calc(100% - 10px);
                    left: 50%;
                    width: 1px;
                    height: 20px;
                    background: darken($color-b02, 10%);
                    transform: rotate(45deg);
                }
                &:after {
                    transform: rotate(-45deg);
                }*/
            &:last-of-type {
                border-bottom: none;
            }
            .img-area {
                position: relative;
                margin-bottom: 0.5em;
                img {
                    border: 3px solid $color-BK;
                    border-radius: 50%;
                    padding: 10px;
                }
                i.number {
                    position: absolute;
                    display: inline-block;
                    left: 5%;
                    width: 2em;
                    height: 2em;
                    background: #ff8201;
                    font-size: 1.2em;
                    color: #fff;
                    font-weight: bold;
                    font-style: normal;
                    line-height: 2em;
                    border-radius: 1.0em;
                }
            }
            .txt-area {
                h5.heading {
                    font-size: 1.2em;
                    text-align: center;
                    margin-bottom: 0.25em;

                }
                .txt {
                    margin-bottom: 0.5em;
                }
                dl {
                    margin-bottom: 0;
                    dt {
                        span {
                            padding: 0 0.5rem;
                            background: -webkit-linear-gradient(transparent 50%, rgba($color-BK, 0.2) 50%);
                            background: linear-gradient(transparent 50%, $color-BK, 0.2, $color-BK, 0.5 90%, $color-BK, 0.2 90%);
                            font-weight: bold;
                        }
                    }
                    dd.txt {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

#fee-pack01 {
    .inner {
        border-color: $color-BK;
        padding-bottom: 0;
        h4.heading04-01 {
            color: $color-BK;
            border-color: $color-BK;
        }
        .content {
            dt {
                background: $color-BK;
            }

        }
    }
}

#fee-pack02 {
    .inner {
        border-color: $color-BK;
        h4.heading04-01 {
            color: $color-BK;
            border-color: $color-BK;
        }
        .content {
            dt {
                background: $color-BK;
            }
        }

        ul.check {
            i {
                color: rgba($color-BK, 0.5);
            }
        }
    }
}

#fee-pack03 {
    .container > .inner {
        border-color: $color-BK;
        padding-bottom: 0;
        h4.heading04-01 {
            color: $color-BK;
            border-color: $color-BK;
        }
        ul.check {
            i {
                color: rgba($color-BK, 0.4);
            }
        }
        article {
            padding-bottom: 0;
            border-bottom: none;
            @include mq-min(md) {
                border-top: 1px dashed darken($color-b02, 10%);
                border-right: none;
                &:nth-of-type(2n-1) {
                    border-right: none;
                    border-left: 1px dashed darken($color-b02, 10%);
                }
                &:first-of-type {
                    border-top: none;

                }
            }

            .inner {
                padding-bottom: 20px;
                border-bottom: 1px dashed darken($color-b02, 10%);
                height: 100%;
                @include mq-min(md) {
                    border: none;
                }
                .row {
                    margin: 0;
                    border: 3px solid $color-b02;
                    .col-6 {
                        position: relative;
                        padding: 0;
                        margin: 0;
                        &:before {
                            content: "";
                            position: absolute;
                            bottom: 0;
                            left: calc(50% - 30px);
                            width: 0;
                            height: 0;
                            border: 30px solid transparent;
                            border-top-width: 20px;
                            border-bottom: 20px solid $color-BK;
                        }
                        i {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            color: $color-WH;
                            font-style: normal;
                            letter-spacing: 0.2em;
                            font-size: 0.7em;
                        }
                    }
                }
                h4.heading {
                    color: $color-BK;
                    margin-top: 0.75em;
                }
                .txt {
                    margin-bottom: 0;
                }
            }
        }
    }
}



#section-news {
    .article-column {
        margin-left: -5px;
        margin-right: -5px;
        article {
            padding-right: 5px;
            padding-left: 5px;
            margin-bottom: 10px;
            .inner {
                @include mq-min(md) {
                    background: #fff;
                    border-bottom: none;
                    margin: 0;
                    padding: 0;
                }
                .img-area {
                    @include mq-min(md) {
                        padding-right: 15px;
                    }
                }
                .txt-area {
                    @include mq-min(md) {
                        padding: 10px 30px;
                    }
                    h5.heading {
                        color: $color-m01;
                        margin: 0;
                    }
                    time {
                        font-size: 0.5em;
                        color: rgba(0, 0, 0, 0.3);
                        display: block;
                        margin-bottom: 0.25rem;
                    }
                    .txt {
                        font-size: 0.8em;
                    }
                }
            }
        }
    }
}

#section-area {
    dl {
        text-align: center;
        dt {
            padding: 0 0.5rem;
            background: -webkit-linear-gradient(transparent 50%, rgba(16, 153, 84, 0.2) 50%);
            background: linear-gradient(transparent 50%, #ff8201, 0.2, #ff8201, 0.5 90%, #ff8201, 0.2 90%);
            font-weight: bold;
            display: inline;
            font-size: 1.25rem;
        }
    }
}


#section-flow {
    article {
        h4.heading {
            position: relative;
            background: #109954;
            color: #fff;
            font-size: 1.3em;
            padding: 0.5em;
        }
        dl {
            position: relative;
            background: #fff;
            border: 3px solid rgba($color-m01, 0.2);
            padding: 1.5rem;
            line-height: 1.3;
            dt {
                color: $color-m01;
                border-bottom: 3px solid $color-m01;
                margin-bottom: 0.4em;
                padding: 0 0 0.25em;
                font-size: 1.2rem;
                .number {
                    position: absolute;
                    top: 0;
                    left: 0;
                    font-size: 0.9em;
                    font-style: normal;
                    color: $color-WH;
                    transform: rotate(-45deg);
                }
            }
            dd {
                margin-bottom: 0;
                /*.line {
                    height: 1.0rem;
                    width: 3px;
                    background: #109954;
                    display: block;
                    position: absolute;
                    bottom: -19px;
                    left: calc(50% - 1.5px);
                    &:before {
                        content: "";
                        display: block;
                        width: 10px;
                        height: 10px;
                        background: #109954;
                        border-radius: 50%;
                        top: -6px;
                        left: -3.5px;
                        position: absolute;
                    }
                }*/
            }
            &:before {
                content: "";
                position: absolute;
                top: -3px;
                left: -3px;
                width: 0;
                height: 0;
                border: 25px solid transparent;
                border-left-color: $color-m01;
                border-top-color: $color-m01;
            }
            /*&:after{
                content: "";
                        display: block;
                        width: 10px;
                        height: 10px;
                        background: #109954;
                        border-radius: 50%;
                        top: -7px;
                        left: calc(50% - 5px);
                        position: absolute;
            }*/
        }
        .inner {
            position: relative;
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                width: 2.0rem;
                height: 100%;
                left: calc(50% - 1rem);
                background: rgba($color-m01, 0.25);
            }
        }
        &.col-md-8 {
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                width: 2.0rem;
                height: 100%;
                left: calc(75% - 1rem);
                background: rgba($color-m02, 0.25);
            }
            .inner {
                &:before {
                    left: 25%;
                    background: rgba($color-m02, 0.25);
                }
                h4.heading {
                    background: $color-m02;
                }
                dl {
                    border-color: rgba($color-m02, 0.2);
                    &:before {
                        border-left-color: $color-m02;
                        border-top-color: $color-m02;
                    }
                    dt {
                        color: $color-m02;
                        border-bottom-color: $color-m02;
                    }
                }
                > dl:last-of-type {
                    margin-bottom: 0;
                }
            }
        }
    }
}


/*#section-about {
    @include mq-min(lg) {
        padding-bottom: 0;
    }
    #about-information {
        @include mq-min(lg) {
            padding-bottom: 3.0rem;
        }
        .col-md-4 {
            margin-bottom: 1.0rem;
            @include mq-min(lg) {
                margin-bottom: 0;
            }
        }
        .col-md-8 {
            .table {
                margin-bottom: 0;
            }
        }
    }

    #about-staff {
        @include mq-min(lg) {
            padding: 3.0rem;
            border-top: 6px solid #c5beb2;
            background-image: -webkit-gradient(linear, 0 0, 100% 100%, color-stop(.25, rgba($color-b01, 0.2)), color-stop(.25, rgba($color-b01, 0.5)), color-stop(.5, rgba($color-b01, 0.5)), color-stop(.5, rgba($color-b01, 0.2)), color-stop(.75, rgba($color-b01, 0.2)), color-stop(.75, rgba($color-b01, 0.5)), to(rgba($color-b01, 0.5)));
            -webkit-background-size: 40px 40px;
        }
        article {
            background: #fff;
            border-radius: 5px;
            padding: 45px 15px 30px;
            @include mq-min(lg) {
                border: 10px solid $color-m01;
                padding: 45px;
            }
            h4.heading {
                font-size: 1.5rem;
                margin-bottom: 0.75em;
                @include mq-min(lg) {
                    font-size: 2.0em;
                }
                span {
                    position: relative;
                    padding: 0 1.5rem;
                    @include mq-min(lg) {
                        padding: 0 2.0em;
                    }
                    &:before,
                    &:after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 120%;
                        width: 2px;
                        transform: rotate(-30deg);
                        background: $color-m01;
                    }
                    &:after {
                        left: 100%;
                        transform: rotate(30deg);
                    }
                }
            }
            .img-area {
                img {
                    border-radius: 50%;
                    padding: 5px;
                    background: $color-b02;
                }
            }
            h5.heading {
                @include mq-min(lg) {
                    text-align: left;
                    font-size: 1.25em;
                    border-left: 5px solid $color-m01;
                    padding-left: 0.5em;
                }
            }
        }
    }

}*/

#section-question {
    article {
        position: relative;
        padding: 1.75em 1.25em 1.5em;
        background: $color-WH;
        margin-bottom: 1.0rem;
        @include mq-min(lg) {

            padding: 3em;
            border: 5px solid #21825191;
            border-radius: 5px;
        }
        h5 {
            border-bottom: 5px solid #109954;
            padding-bottom: 0.25em;
            font-size: 1.1em;
            @include mq-min(lg) {
                border-bottom-color: #c5beb257;
                position: relative;
                padding-left: 2.0rem;
            }
            span {
                /*&:before{
                        content: "";
    display: block;
    position: absolute;
    top: -10px;
    left: -10px;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-top: 15px solid $color-m01;
    transform: rotate(-45deg);
                }*/
                &:after {
                    content: "?";
                    display: block;
                    position: absolute;
                    left: calc(50% - 15px);
                    top: -10px;
                    width: 30px;
                    height: 30px;
                    background: $color-m01;
                    color: $color-WH;
                    line-height: 30px;
                    text-align: center;
                    border-radius: 50%;
                    @include mq-min(lg) {
                        left: 0;

                    }
                }
            }
            @include mq-min(md) {
                font-size: 1.25em;
            }
            @include mq-min(lg) {
                text-align: left;
            }
        }
        .txt,
        .txt-area p:last-of-type {
            margin-bottom: 0;
        }
    }
}

#section-contact {
    input,
    textarea {
        width: 100%;
        border: 1px solid #e6e6e6;
        padding: 0.5em;
        border-radius: 3px;
        line-height: 1.5;
        border-radius: 6px;
        border-width: 1px;
        box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.1) inset
    }
    .form-tag {
        position: absolute;
        top: 5px;
        right: 5px;
        font-size: 0.7em;
        padding: 0.25em 0.5em;
        border-radius: 3px;
        &.required {
            background: $color-a01;
            color: $color-txt;
        }
        &.selected {
            background: rgba($color-WH, 0.8);
            color: $color-b01;
        }
    }
    .btn-area {
        /*
        padding:10px;
        border:1px solid #fff;
        box-shadow:1px 3px 3px 0 #000 inset;
        */
        button[type="submit"] {
            font-size: 1.2em;
            font-weight: bold;
            letter-spacing: 0.1em;
            background: darken($color-a01, 3%);
            box-shadow: 0 3px 0 0 darken($color-a01, 10%);
        }
    }
}
