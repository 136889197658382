@charset "utf-8";
* {
    box-sizing: border-box;
}

html,
body {
    font: 16px/1.7 'Noto Sans', 'Noto Sans JP', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', '游ゴシック', YuGothic, Verdana, メイリオ, Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
    color: $color-txt;
    text-align: justify;
    text-justify: inter-ideograph;
    background: $color-b02;
    @include mq-min(lg) {
        font-size: 18px;
    }
}

a {
    color: $color-m01;
    text-decoration: underline;
    &:hover {
        text-decoration: none;
    }
}

img {
    width: 100%;
    height: auto;
}

p {
    margin-bottom: 1.0em;
}

mark,
.mark {
    padding: 0 1.0em;
    background: linear-gradient(transparent 50%, rgba($color-a02, 0.5) 50%, rgba($color-a02, 0.5) 90%);
    font-weight: bold;
    color: darken($color-txt, 20);
}

time {
    font-size: 0.5em;
    color: rgba($color-txt, 0.5);
}

/***
* 見出し **********/

h1 {
    @include heading(1rem, 0, 0);
    display: none;
}

h2 {
    @include heading(1.5rem, 0.1em, 0);
    @include mq-min(lg) {
        font-size: 2.0rem;
        padding: 1.5rem 0;
    }
}

h3 {
    @include heading(1.5rem, 0.03em, 0 0 1.0em);
    @include mq-min(lg) {
        margin-bottom: 2.0em;
    }
    @include mq-min(xl) {
        font-size: 2.0rem;
        margin-bottom: 2.0em;
    }
}

h4 {
    @include heading(1.25rem, 0, 0 0 .5em);
    @include mq-min(lg) {
        font-size: 1.25rem;
    }
}

h5 {
    @include heading(1.1rem, 0, 1.0em 0 0.5em);
    @include mq-min(lg) {
        font-size: 1.25em;
    }
}

h6 {
    @include heading(1.0rem, .05em, .5em 0 .25em);
}


/***
* FORM **********/

input,
textarea {
    width: 100%;
    padding: 0;
    border: none;
}

:placeholder-shown {
    color: rgba($color-weak, 0.5);
}

/* Google Chrome, Safari, Opera 15+, Android, iOS */

::-webkit-input-placeholder {
    color: rgba($color-weak, 0.5);
}

/* Firefox 18- */

:-moz-placeholder {
    color: rgba($color-weak, 0.5);
    opacity: 1;
}

/* Firefox 19+ */

::-moz-placeholder {
    color: rgba($color-weak, 0.5);
    opacity: 1;
}

/* IE 10+ */

:-ms-input-placeholder {
    color: rgba($color-weak, 0.5);
}
